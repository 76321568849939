import React, { useState } from "react";
import Dropdown from "../CustomDropdown/Dropdown";
import { IoMdDownload } from "react-icons/io";
import { FaShareAlt } from "react-icons/fa";
import "./MultiStepForm.css";
import COLORS from "../../utils/colors";

const options = [
  { value: "bank1", label: "bank1" },
  { value: "bank2", label: "bank2" },
  { value: "bank3", label: "bank3" },
];

const options1 = [
  { value: "friend", label: "Transfer to Friend" },
  { value: "own", label: "Own Account" },
  { value: "loan", label: "Loan Payment" },
];

const labelStyle = {
  fontFamily: "Open Sans",
  fontSize: 11,
  fontWeight: 800,
  textAlign: "left",
  marginBottom: "1%",
  textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const inputFieldStyle = {
  borderRadius: 10,
  border: "1px solid #ccc",
  backgroundColor: "rgb(211, 211, 211, 0.5)",
  height: 38,
  textAlign: "left",
  width: 250,
  fontSize: 13,
  padding: 10,
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.4)",
};

const buttonStyle = {
  backgroundColor: COLORS.green,
  borderRadius: 10,
  textAlign: "center",
  width: 156,
  fontFamily: "Open Sans",
  fontSize: 15,
  fontWeight: "bold",
  marginTop: "8%",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const roundButtonStyle = {
  width: 35,
  height: 35,
  backgroundColor: COLORS.green,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 20,
  borderWidth: 2,
  borderColor: "#d9d9d9",
  borderStyle: "solid",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const MultiStepForm = () => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
  };

  return (
    <div className="col-sm-12" style={{ padding: 20, fontFamily: "Open Sans" }}>
      <h6
        style={{
          fontSize: 12,
          fontWeight: "bold",
          textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Bank Transfer
      </h6>
      <form
        id="multistepsform"
        style={{ marginTop: "5%", padding: 20, fontFamily: "Open Sans" }}
        className="col-sm-7 boxShadow"
      >
        {/* Progress Bar */}
        <ul
          id="progressbar"
          style={{ marginRight: "5%", marginTop: "2%", marginBottom: "5%" }}
        >
          <li className={step === 1 ? "active" : ""}>Bank Details</li>
          <li className={step === 2 ? "active" : ""}>Personal Details</li>
          <li className={step === 3 ? "active" : ""}>Review Details</li>
          <li className={step === 4 ? "active" : ""}>Transaction Status</li>
        </ul>

        {/* Fieldsets */}
        {/* Bank Details */}
        <fieldset style={{ display: step === 1 ? "block" : "none" }}>
          {/* <h2 className="fs-title">Bank Details</h2> */}
          {/* <h3 className="fs-subtitle"></h3> */}
          <div className="row gap-5">
            <div className="col-sm-5">
              <div style={labelStyle}>Select Bank</div>
              <Dropdown
                options={options}
                text="Select Bank"
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-5">
              <div style={labelStyle}>Routing Number</div>
              <input
                type="text"
                name="pass"
                placeholder="ex. 123456789"
                style={inputFieldStyle}
              />
            </div>
          </div>

          <div className="row gap-5 mt-4">
            <div className="col-sm-5">
              <div style={labelStyle}>Account Number</div>
              <input
                type="text"
                name="pass"
                placeholder="9876543"
                style={inputFieldStyle}
              />
            </div>
            <div className="col-sm-5">
              <div style={labelStyle}>Retype Account Number</div>
              <input
                type="text"
                name="pass"
                placeholder="....."
                style={inputFieldStyle}
              />
            </div>
          </div>

          <input
            type="button"
            name="next"
            className="next action-button"
            value="Next"
            onClick={handleNext}
            style={buttonStyle}
          />
        </fieldset>

        {/* Personal Details */}
        <fieldset style={{ display: step === 2 ? "block" : "none" }}>
          {/* <h2 className="fs-title">Personal Details</h2>
        <h3 className="fs-subtitle">We will never sell it</h3> */}
          <div className="row gap-5">
            <div className="col-sm-5">
              <div style={labelStyle}>Purpose of Payments</div>
              <Dropdown
                options={options1}
                text="Transfer to Friend"
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-5">
              <div style={labelStyle}>Recipient Name</div>
              <input
                type="text"
                name="pass"
                placeholder="John Doe"
                style={inputFieldStyle}
              />
            </div>
          </div>

          <div className="row gap-5 mt-4">
            <div className="col-sm-5">
              <div style={labelStyle}>Amount</div>
              <input
                type="text"
                name="pass"
                placeholder="ex. $ 100"
                style={inputFieldStyle}
              />
            </div>
            <div className="col-sm-5">
              <div style={labelStyle}>Mobile Number</div>
              <input
                type="text"
                placeholder="00000000000"
                style={inputFieldStyle}
              />
            </div>
          </div>

          <div className="d-flex justify-content-evenly">
            <input
              type="button"
              name="previous"
              className="previous action-button"
              value="Previous"
              style={buttonStyle}
              onClick={handlePrevious}
            />
            <input
              type="button"
              name="next"
              className="next action-button"
              value="Next"
              style={buttonStyle}
              onClick={handleNext}
            />
          </div>
        </fieldset>

        {/* Review Details */}
        <fieldset style={{ display: step === 3 ? "block" : "none" }}>
          {/* <h2 className="fs-title">Social Profiles</h2>
        <h3 className="fs-subtitle">Your presence on the social network</h3> */}

          <div className="row gap-5">
            <div className="col-sm-5">
              <div style={labelStyle}>Selected Bank</div>
              <input
                type="text"
                placeholder="ABC Bank Ltd."
                style={inputFieldStyle}
                disabled
              />
            </div>
            <div className="col-sm-5">
              <div style={labelStyle}>Routing Nunmber</div>
              <input
                type="text"
                placeholder="ex. 123456789"
                style={inputFieldStyle}
                disabled
              />
            </div>
          </div>

          <div className="row gap-5 mt-4">
            <div className="col-sm-5">
              <div style={labelStyle}>Account Number</div>
              <input
                type="text"
                name="pass"
                placeholder="9876543"
                style={inputFieldStyle}
                disabled
              />
            </div>
            <div className="col-sm-5">
              <div style={labelStyle}>Recipient Name</div>
              <input
                type="text"
                name="pass"
                placeholder="John Doe"
                style={inputFieldStyle}
                disabled
              />
            </div>
          </div>

          <div className="row gap-5 mt-4">
            <div className="col-sm-5">
              <div style={labelStyle}>Amount</div>
              <input
                type="text"
                name="pass"
                placeholder="ex. $ 100"
                style={inputFieldStyle}
                disabled
              />
            </div>
            <div className="col-sm-5">
              <div style={labelStyle}>Mobile Number</div>
              <input
                type="text"
                placeholder="00000000000"
                style={inputFieldStyle}
                disabled
              />
            </div>
          </div>

          <div className="d-flex justify-content-evenly">
            <input
              type="button"
              name="previous"
              className="previous action-button"
              value="Previous"
              style={buttonStyle}
              onClick={handlePrevious}
            />
            <input
              type="button"
              name="submit"
              className="submit action-button"
              value="Submit"
              style={buttonStyle}
              onClick={handleNext}
            />
          </div>
        </fieldset>

        {/* Transaction Status */}
        <fieldset style={{ display: step === 4 ? "block" : "none" }}>
          {/* <h2 className="fs-title">Social Profiles</h2> */}
          <h6
            style={{
              marginBottom: "2%",
              textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
              color: COLORS.green,
              fontWeight: 700,
            }}
          >
            Transaction Successful
          </h6>

          <div
            className="col-sm-12"
            style={{
              backgroundColor: "#d9d9d9",
              borderRadius: 7,
              padding: 20,
              boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="row justify-content-between gap-5">
              <div
                className="col"
                style={{ ...labelStyle, textAlign: "right" }}
              >
                <div>Transaction ID</div>
                <div className="mt-2">Transaction Date</div>
                <div className="mt-2">Type</div>
                <div className="mt-2">Transfer To</div>
                <div className="mt-2">Recipient Name</div>
                <div className="mt-2">Account Number</div>
                <div className="mt-2">Amount</div>
              </div>
              <div
                className="col"
                style={{ ...labelStyle, textAlign: "left", opacity: 0.8 }}
              >
                <div>2748569855</div>
                <div className="mt-2">03 Mar 2024 (05:13 pm)</div>
                <div className="mt-2">Bank Transfer</div>
                <div className="mt-2">ABC Bank Ltd.</div>
                <div className="mt-2">John Doe</div>
                <div className="mt-2">0000000000000</div>
                <div className="mt-2">$ 100</div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "5%",
            }}
          >
            <div style={roundButtonStyle}>
              <IoMdDownload size={25} color="#d9d9d9" />
            </div>
            <div
              style={{
                ...roundButtonStyle,
                backgroundColor: "#d9d9d9",
                marginLeft: "3%",
              }}
            >
              <FaShareAlt size={20} color={COLORS.orange} />
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default MultiStepForm;
