import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        padding: 20,
        fontFamily: "Open Sans",
        overflowY: "auto",
        maxHeight: "100%",
        fontSize: 14,
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: 18,
          marginBottom: "1%",
          textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Privacy Policy
      </div>
      <p>
        VIVAPAY takes your privacy very seriously. This Privacy Policy explains
        how vivapay collects, uses, and discloses information, and your choices
        for managing your information preferences.
      </p>
      <p>
        This Privacy Policy describes vivapay’s data practices associated with
        our website (vivapay.com) and vivapay services ("Services"), and the
        choices that vivapay provides in connection with our collection and use
        of your information. This Privacy Policy is intended for website
        publisher customers ("Publishers"), website merchant customers
        ("Merchants") and individual users of websites and apps. For Publishers
        and Merchants, this Policy explains how vivapay may collect, use and
        disclose information associated with your company and with your
        company’s websites and apps that use vivapay Services. For individual
        website and app users, this Privacy Policy explains how vivapay may
        collect, use, and disclose information when you visit our website or
        when you use any website or app that uses vivapay Services. Publishers
        and Merchants and other clients may also have their own policies that
        govern how they collect, use, and share data. These policies may differ
        from vivapay’s policies described in this Privacy Policy. Please consult
        the privacy policies of the websites you visit and apps you use to
        become familiar with their privacy practices and to learn about any
        choices that these companies may offer with respect to their information
        practices. In addition, any website containing our Services may contain
        links to websites or content operated and maintained by third parties,
        over which we have no control. We encourage you to review the privacy
        policy of a third-party website before disclosing any information to the
        website.
      </p>
      <p>
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which is accessible at Drag Racing Lights unless
        otherwise defined in this Privacy Policy.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>
        Information Collection and Use
      </h6>
      <p>
        vivapay collects data in a variety of ways - including through the use
        of log files, pixel tags, cookies, and/or similar technologies. Examples
        of the types of data that we collect are:
      </p>
      <ul>
        <li>
          Browser information (e.g. URL, browser type, ‘click through’ data).
        </li>
        <li>
          Ad reporting or delivery data (e.g. size/type of ad, ad impressions,
          location/format of ad, data about interactions with the ad);
        </li>
        <li>
          Device-type information (e.g. screen dimensions, device brand and
          model);
        </li>
        <li>Information about your activities on our website and Services.</li>
      </ul>

      <p>
        We may combine information that does not directly identify an individual
        with data collected from other sources and disclose the combined
        information to participating publishers, advertisers and ad networks so
        that they can determine whether to bid on ad inventory and in order to
        improve the relevance of the advertising presented to users. We also use
        the information we collect to host, operate, maintain, secure, and
        further develop and improve our Services, such as to keep track of
        advertising delivery and to measure the effectiveness of advertising
        delivered through our Services, and investigate compliance with
        vivapay’s policies and terms and conditions. Some of the third parties
        that we work with may contribute additional data to us directly, which
        we may combine with our own in order to help us provide a better
        service. We do not collect any information that could be used to
        directly identify an individual.
      </p>

      <p>
        vivapay does not engage in activities that require parental notice or
        consent under the Children’s Online Privacy Protection Act (COPPA). If
        you believe that vivapay has inadvertently collected information from a
        child under 13 that is subject to parental notice and consent under
        COPPA, please contact vivapay using the contact information below to
        request deletion of the information.
      </p>
      {/* Add the rest of the HTML content here */}

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>
        Cookies and Other Similar Technologies
      </h6>
      <p>
        We use cookies (a small file containing a string of characters that
        uniquely identifies your Web browser), Web beacons (an electronic file
        placed within a Web site that monitors usage), pixels, etags, and
        similar technologies to operate and improve our website and Services,
        including for interest-based advertising as described below. Some of our
        Service Providers (defined below) may also use such technologies in
        connection with the services they perform on our behalf.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>Information Sharing</h6>
      <p>
        We will disclose contact and billing information to third parties only
        as described in this Privacy Policy:
      </p>

      <ul>
        <li>with your express permission</li>
        <li>
          with our affiliates, which include entities controlling, controlled
          by, or under common control with vivapay;
        </li>
        <li>
          where we contract with third parties to provide certain services, such
          as advertising, analytics, data management services, web hosting, and
          web development ("Service Providers"). We ask Service Providers to
          confirm that their privacy and security practices are consistent with
          ours, we provide our Service Providers with only the information
          necessary for them to perform the services we request, and Service
          Providers are prohibited from using such information for purposes
          other than as specified by vivapay;
        </li>
        <li>
          in the event that vivapay is merged, sold, or in the event of a
          transfer of some or all of our assets (including in bankruptcy), or in
          the event of another corporate change, we may disclose or transfer
          information in connection with such transaction; and where we believe
          it is necessary to protect vivapay or our users; to enforce our terms
          or the legal rights of vivapay or others; or to comply with a request
          from governmental authorities, legal process, or other legal
          obligations.
        </li>
      </ul>

      <p>
        We may also share and disclose other information that we collect,
        including aggregate information, as we consider necessary to develop and
        provide our Services, including in the ways described above. The
        information that we share in this way would not be considered to
        personally identify an individual.
      </p>

      <p>
        vivapay may also be required to disclose information in response to
        lawful requests by public authorities, including to meet national
        security or law enforcement requirements.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>
        Reviewing and Updating Information
      </h6>

      <p>
        vivapay takes reasonable steps to ensure that information is accurate,
        complete, current, and reliable for its intended use. For contact or
        billing information submitted through our website, you may review,
        correct, update, or change your information, request that we deactivate
        your account, or remove your information from our direct marketing
        efforts, at any time by e-mailing us on email.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>
        International Information Transfers
      </h6>

      <p>
        Please be aware that the information we collect, including contact and
        billing information, may be transferred to and maintained on servers or
        databases located outside your state, province, country, or other
        jurisdiction, where the privacy laws may not be as protective as those
        in your location. If you are located outside of the United States,
        please be advised that we process and store information in the United
        States and your consent to this Privacy Policy or use of vivapay
        Services represents your agreement to this processing.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>Security</h6>

      <p>
        Information that we collect is stored using procedures and practices
        reasonably designed to help protect information from unauthorized
        access, destruction, use, modification, or disclosure.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>Policy Updates</h6>

      <p>
        From time to time, we may change this Privacy Policy. If we decide to
        change this Privacy Policy, in whole or in part, we will inform you by
        posting the revised Privacy Policy on the vivapay website. Those changes
        will go into effect on the effective date disclosed in the revised
        Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
