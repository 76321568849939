import React, { useState, useEffect } from "react";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./sidebarStyle.css";
import { IconContext } from "react-icons";

function Sidebar() {
  const [sidebar, setSidebar] = useState(true);
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState("");

  useEffect(() => {
    const currentMenuItem = SidebarData.find(
      (item) => item.path === location.pathname
    );
    if (currentMenuItem) {
      setSelectedMenuItem(currentMenuItem.title);
    }
  }, [location]);

  const showSidebar = () => setSidebar(true);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div
          className="navbar"
          style={{
            justifyContent: "flex-end",
            position: "sticky",
            top: 0,
            boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Link to="#" className="menu-bars" style={{ marginRight: "4%" }}>
            <img src="/asstes/images/Avatar.svg" />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <img src="/asstes/images/logo.svg" />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link
                    to={item.path}
                    // className={
                    //   location.pathname === item.path ? "active-link" : ""
                    // }
                    className={
                      selectedMenuItem === item.title ? "active-link" : ""
                    }
                  >
                    {" "}
                    {/* Apply active class if current path matches */}
                    {selectedMenuItem === item.title
                      ? item.iconActive
                      : item.icon}
                    {/* {item.icon} */}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Sidebar;
