import React from "react";
import { cardTitleStyle, cardBoxLayout } from "./styling";
import COLORS from "../../utils/colors";

export default function Transactions() {
  return (
    <div className="col-sm-12 boxShadow boxShadowHover" style={cardBoxLayout}>
      <h6 style={cardTitleStyle}>Transactions</h6>

      <div className="row">
        <div
          className="col-sm-8"
          style={{ padding: 20, fontFamily: "Open Sans" }}
        >
          <div style={{ fontSize: 14, fontWeight: 600 }}>Bill Payment</div>
          <div style={{ fontSize: 11, color: COLORS.grey }}>
            March 10, 2024 11:01 AM
          </div>
        </div>

        <div className="col-sm-4" style={{ padding: 20 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
              color: COLORS.green,
              fontSize: 15,
            }}
          >
            $150.00
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: "-5%" }}>
        <div
          className="col-sm-8"
          style={{ padding: 20, fontFamily: "Open Sans" }}
        >
          <div style={{ fontSize: 14, fontWeight: 600 }}>Mobile Bundle</div>
          <div style={{ fontSize: 11, color: COLORS.grey }}>
            March 12, 2024 06:47 PM
          </div>
        </div>

        <div className="col-sm-4" style={{ padding: 20 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
              color: COLORS.green,
              fontSize: 15,
            }}
          >
            $5.00
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: "-5%" }}>
        <div
          className="col-sm-8"
          style={{ padding: 20, fontFamily: "Open Sans" }}
        >
          <div style={{ fontSize: 14, fontWeight: 600 }}>Bill Payment</div>
          <div style={{ fontSize: 11, color: COLORS.grey }}>
            March 12, 2024 06:47 PM
          </div>
        </div>

        <div className="col-sm-4" style={{ padding: 20 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
              color: COLORS.green,
              fontSize: 15,
            }}
          >
            $15.00
          </div>
        </div>
      </div>

      <div className="row" style={{ marginTop: "-5%" }}>
        <div
          className="col-sm-8"
          style={{ padding: 20, fontFamily: "Open Sans" }}
        >
          <div style={{ fontSize: 14, fontWeight: 600 }}>Bill Payment</div>
          <div style={{ fontSize: 11, color: COLORS.grey }}>
            March 12, 2024 06:47 PM
          </div>
        </div>

        <div className="col-sm-4" style={{ padding: 20 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
              color: COLORS.green,
              fontSize: 15,
            }}
          >
            $15.00
          </div>
        </div>
      </div>
    </div>
  );
}
