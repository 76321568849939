import React from "react";
import img from "../../assets/images/map@2x.png";

const ServiceProviders = ({ data, onSelect }) => {
  const handleProviderClick = (provider) => {
    // Call the onSelect function with the selected provider
    onSelect(provider);
  };

  return (
    <div className="service-providers-container">
      {data.map((provider, index) => (
        <img
          key={index}
          src={img} // Replace this with provider.image if available
          alt={provider.name}
          style={{
            marginRight: 10,
            cursor: "pointer",
            width: 100,
            height: 100,
          }}
          onClick={() => handleProviderClick(provider)}
        />
      ))}
    </div>
  );
};

export default ServiceProviders;
