import { isMobile } from "react-device-detect";
import COLORS from "../../utils/colors";

const containerLayout = {
  backgroundColor: COLORS.orange,
  borderWidth: 5,
  borderColor: "#000",
  height: isMobile ? "40%" : "50%",
  padding: 30,
  width: isMobile ? "90%" : "30%",
  borderRadius: 7,
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const containerLayoutSignup = {
  backgroundColor: COLORS.orange,
  borderWidth: 5,
  borderColor: "#000",
  height: isMobile ? "60%" : "80%",
  padding: 30,
  width: isMobile ? "90%" : "30%",
  borderRadius: 7,
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const logoStyles = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#fff",
  marginTop: "-11%",
  width: "90%",
  borderRadius: 10,
};

const formLayout = {
  width: "90%",
  marginTop: "5%",
  display: "flex",
  alignItems: "center",
};

const labelStyle = {
  fontFamily: "Open Sans",
  fontWeight: "600",
  color: "#fff",
  fontSize: 15,
};

const inputFieldStyle = {
  backgroundColor: "#fff",
  borderRadius: 10,
  height: 36,
  marginTop: "1%",
  borderColor: "#fff",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const buttonStyle = {
  backgroundColor: COLORS.green,
  width: "100%",
  borderRadius: 10,
  height: 40,
  marginTop: "10%",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  border: "none",
  fontFamily: "Open Sans",
  fontWeight: "600",
  color: COLORS.white,
  fontSize: 16,
};

export {
  containerLayout,
  containerLayoutSignup,
  logoStyles,
  formLayout,
  labelStyle,
  inputFieldStyle,
  buttonStyle,
};
