import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import COLORS from "../../utils/colors";

const LineChart = () => {
  useEffect(() => {
    const options = {
      noData: {
        text: "No data is available!",
        align: "center",
        verticalAlign: "middle",
      },
      chart: {
        type: "line",
        background: "transparent",
        zoom: {
          enabled: false,
        },
        events: {
          dataPointSelection: function (event, chartContext, config) {
            //console.log(chartContext, config);
          },
        },
        selection: {
          enabled: true,
          type: "xy",
          fill: {
            color: "#A30606",
            opacity: 0.0,
          },
          stroke: {
            width: 3,
            dashArray: 4,
            color: "#A30606",
            opacity: 0.0,
          },
        },
      },
      series: [
        {
          name: "Credit",
          data: [10, 41, 35, 51, 62, 91, 148],
          color: COLORS.green,
        },
        {
          name: "Debit",
          data: [10, 82, 68, 60, 49, 91, 108],
          color: COLORS.orange,
        },
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      theme: {
        mode: "light",
        //palette: "palette1"
      },
      grid: {
        show: true,
      },
      tooltip: {
        shared: false,
        // intersect: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
      },
      marker: {
        size: 8,
        fillColor: "#fff",
        strokeColor: "red",
        radius: 2,
        cssClass: "apexcharts-custom-class",
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "darken",
            value: 0.15,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "darken",
            value: 0.35,
          },
        },
      },
      markers: {
        size: 6,
        strokeColors: "#FFFFFF",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        // discrete: [
        //   {
        //     seriesIndex: 0,
        //     dataPointIndex: 2,
        //     fillColor: "#000",
        //     strokeColor: "#fff",
        //     size: 5,
        //   },
        // ],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: function (e) {
          var seriesIndex = e.target.getAttribute("index");
          var dataPointIndex = e.target.getAttribute("j");
          console.log(
            new Date(chart.w.globals.seriesX[seriesIndex][dataPointIndex])
          );
          selectPoint(seriesIndex, dataPointIndex);
        },
        onDblClick: function (e) {
          console.log(e);
        },
      },
      labels: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      xaxis: {
        type: "category",
        labels: {
          style: {
            // colors: ["#FF5733", "#33FF57"]
            fontSize: 12,
            fontFamily: "Open Sans",
            fontWeight: "600",
          },
        },
      },
    };

    const chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
    chart.toggleDataPointSelection(1, 2);

    function selectPoint(seriesIndex, dataPointIndex) {
      let selectedPoint = {
        seriesIndex: parseInt(seriesIndex),
        dataPointIndex: parseInt(dataPointIndex),
        fillColor: "#A30606",
        strokeColor: "#fff",
        size: 5,
      };
      let discretes = this.tooglePoint(
        this.chartOptions.markers.discrete,
        selectedPoint
      );
      chart.opts.markers.discrete = discretes;
    }

    function tooglePoint(discretes, selectedPoint) {
      let index = discretes.findIndex((p) => {
        return (
          p.seriesIndex == selectedPoint.seriesIndex &&
          p.dataPointIndex == selectedPoint.dataPointIndex
        );
      });
      if (index > -1) {
        discretes.splice(index, 1);
      } else {
        discretes.push(selectedPoint);
      }
      return discretes;
    }
  }, []);

  return <div id="chart"></div>;
};

export default LineChart;
