import React from "react";
import { FaPlus } from "react-icons/fa";
import { RiVisaLine } from "react-icons/ri";
import { cardBoxLayout } from "./styling";
import COLORS from "../../utils/colors";

const layoutContainer = {
  backgroundColor: "#fff",
  borderRadius: 7,
  height: 113,
};

const titleStyle = {
  fontSize: 14,
  fontWeight: 600,
  textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
  marginLeft: "2%",
  marginTop: "4%",
};

const columnLayoutStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const iconLayout = {
  width: 35,
  height: 35,
  borderRadius: 20,
  backgroundColor: COLORS.orange,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const iconLayout1 = {
  width: 35,
  height: 35,
  borderRadius: 7,
  backgroundColor: "#D9D9D9",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
  marginBottom: "10%",
};

const textStyle = {
  fontSize: 10,
  fontFamily: "Open Sans",
  marginTop: "5%",
  fontWeight: 600,
};

const textStyle1 = {
  fontSize: 10,
  fontFamily: "Open Sans",
  // marginTop: "5%",
  fontWeight: 600,
};

export default function DepositCard() {
  return (
    <div
      className="col-sm-5 m-1 align-content-center boxShadow boxShadowHover"
      style={layoutContainer}
    >
      <div style={titleStyle}>Deposit</div>
      <div className="row" style={{ marginTop: "2%" }}>
        <div className="col" style={columnLayoutStyle}>
          <div style={iconLayout}>
            <FaPlus color={COLORS.white} />
          </div>
          <p style={{ ...textStyle, marginTop: 8 }}>Add Card</p>
        </div>

        <div className="col" style={{ ...columnLayoutStyle, marginTop: "-5%" }}>
          <div style={iconLayout1}>
            <RiVisaLine color="#03396c" size={30} />
          </div>
          <div style={textStyle1}>A/C: ****5152</div>
          <div style={textStyle1}>Ex: 10/09/28</div>
        </div>

        <div className="col" style={{ ...columnLayoutStyle, marginTop: "-5%" }}>
          <div style={iconLayout1}>
            <img
              src="/asstes/images/mastercard.svg"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                marginTop: "25%",
              }}
              alt="mastercard"
            />
          </div>
          <div style={textStyle1}>A/C: ****5152</div>
          <div style={textStyle1}>Ex: 10/09/28</div>
        </div>
      </div>
    </div>
  );
}
