import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import COLORS from "../../utils/colors";

const labelStyle = {
  fontFamily: "Open Sans",
  fontSize: 12,
  fontWeight: 800,
  textAlign: "left",
  marginBottom: "5%",
  textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const inputFieldStyle = {
  borderRadius: 10,
  border: "1px solid #ccc",
  backgroundColor: "rgb(211, 211, 211, 0.5)",
  height: 38,
  textAlign: "left",
  width: 250,
  fontSize: 12,
  padding: 10,
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.4)",
  color: "#000",
  fontWeight: 600,
  marginLeft: "5%",
};

const buttonStyle = {
  backgroundColor: COLORS.white,
  borderRadius: 5,
  // textAlign: "center",
  width: 30,
  height: 42,
  fontFamily: "Open Sans",
  fontSize: 15,
  fontWeight: "bold",
  //   border: "none",
  marginTop: "5%",
  borderColor: COLORS.orange,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  float: "right",
  marginBottom: "5%",
};

const UserProfile = () => {
  // Initial user data state
  const [userData, setUserData] = useState({
    name: "John Doe",
    email: "john@example.com",
    phone: "123-456-7890",
    // Add more fields as needed
  });

  // State to control edit mode
  const [editMode, setEditMode] = useState(false);

  // Event handler for input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  // Event handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add logic to update user data here
    setEditMode(false); // Exit edit mode after submission
  };

  return (
    <div className="col-sm-12">
      <h6
        style={{
          fontSize: 12,
          fontWeight: "bold",
          textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Settings / Profile Information
      </h6>

      <div className="row justify-content-center" style={{ marginTop: "5%" }}>
        <div
          className="col-sm-4 boxShadow"
          style={{
            backgroundColor: "#fff",
            borderRadius: 7,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            // padding: 20,
          }}
        >
          <img
            // src={userData.avatar}
            src="/asstes/images/Avatar.svg"
            style={{ width: 100, height: 100, marginTop: "5%" }}
            alt="User Avatar"
          />
          {editMode ? (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "3%",
                alignItems: "center",
              }}
            >
              <label style={labelStyle}>
                Name:
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleInputChange}
                  style={inputFieldStyle}
                />
              </label>
              <label style={labelStyle}>
                Email:
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  style={inputFieldStyle}
                />
              </label>
              <label style={labelStyle}>
                Phone:
                <input
                  type="tel"
                  name="phone"
                  value={userData.phone}
                  onChange={handleInputChange}
                  style={inputFieldStyle}
                />
              </label>
              {/* Add more fields for other user information */}
              <button
                type="submit"
                className="boxShadowHover"
                style={{
                  ...buttonStyle,
                  width: 100,
                  border: "none",
                  backgroundColor: COLORS.green,
                  color: "#fff",
                }}
              >
                Update
              </button>
            </form>
          ) : (
            <>
              <div className="mt-3">
                <p style={labelStyle}>
                  Name: <span style={{ fontSize: 14 }}>{userData.name}</span>
                </p>
                <p style={labelStyle}>
                  Email: <span>{userData.email}</span>
                </p>
                <p style={labelStyle}>
                  Phone: <span>{userData.phone}</span>
                </p>
              </div>
              {/* Display other user information */}
              <button
                className="boxShadowHoverColor"
                onClick={() => setEditMode(true)}
                style={{ border: "none", float: "right", marginBottom: "5%" }}
              >
                <FaRegEdit color={COLORS.orange} size={25} />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
