import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceProviders from "../../components/HorizontalScroll/ServiceProviders";
import COLORS from "../../utils/colors";

const labelStyle = {
  fontFamily: "Open Sans",
  fontSize: 11,
  fontWeight: 800,
  textAlign: "left",
  marginBottom: "1%",
  textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const inputFieldStyle = {
  borderRadius: 10,
  border: "1px solid #ccc",
  backgroundColor: "rgb(211, 211, 211, 0.5)",
  height: 38,
  textAlign: "left",
  width: 250,
  fontSize: 12,
  padding: 10,
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.4)",
  color: "#000",
  fontWeight: 600,
};

const buttonStyle = {
  backgroundColor: COLORS.green,
  borderRadius: 10,
  textAlign: "center",
  width: 156,
  height: 42,
  fontFamily: "Open Sans",
  fontSize: 15,
  fontWeight: "bold",
  color: "#fff",
  border: "none",
  marginTop: "-5%",
};

// Dummy data array
const dummyData = [
  { name: "Provider 1", image: "provider1.jpg" },
  { name: "Provider 2", image: "provider2.jpg" },
  { name: "Provider 3", image: "provider3.jpg" },
  { name: "Provider 4", image: "provider1.jpg" },
  { name: "Provider 5", image: "provider2.jpg" },
  { name: "Provider 6", image: "provider3.jpg" },
  { name: "Provider 7", image: "provider1.jpg" },
  { name: "Provider 8", image: "provider2.jpg" },
  { name: "Provider 9", image: "provider3.jpg" },
  // Add more dummy data as needed
];

export default function PayBill() {
  const navigate = useNavigate();
  const [selectedProvider, setSelectedProvider] = useState(null);

  const handleSelectProvider = (provider) => {
    // Handle the selected provider here
    setSelectedProvider(provider.name);
    // console.log(provider.name);
  };

  return (
    <div style={{ fontFamily: "Open Sans", padding: 20 }}>
      <h6
        style={{
          fontSize: 12,
          fontWeight: "bold",
          textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Bill Payments / Electricity
      </h6>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "5%",
        }}
      >
        <div
          className="boxShadow"
          style={{
            backgroundColor: "#fff",
            padding: 20,
            borderRadius: 7,
            width: "60%",
          }}
        >
          <div style={{ padding: 20 }}>
            <div style={labelStyle}>Service Provider</div>
            <ServiceProviders
              data={dummyData}
              onSelect={handleSelectProvider}
            />
          </div>

          <div
            className="row align-items-center justify-content-center"
            style={{ gap: 75, marginTop: "2%" }}
          >
            <div className="col-sm-5">
              <div style={labelStyle}>Select Biller</div>
              <input
                type="text"
                name="pass"
                // placeholder="ex. 123456789"
                value={selectedProvider}
                style={inputFieldStyle}
                disabled
              />
            </div>
            <div className="col-sm-5">
              <div style={labelStyle}>Consumer ID</div>
              <input
                type="text"
                name="pass"
                placeholder="enter consumer id ..."
                style={inputFieldStyle}
              />
            </div>

            <input
              type="button"
              name="next"
              className="boxShadow"
              value="Check Bill"
              onClick={() => navigate("/billdetails")}
              style={buttonStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
