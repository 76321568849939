import COLORS from "../../utils/colors";

const cardTitleStyle = {
  padding: 5,
  color: COLORS.green,
  fontWeight: "700",
  textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
  fontSize: 14,
};

const cardBoxLayout = {
  backgroundColor: "#fff",
  borderRadius: 8,
  fontFamily: "Open Sans",
  padding: 15,
  marginTop: "2.5%",
};

export { cardTitleStyle, cardBoxLayout };
