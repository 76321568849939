import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 10,
    border: "1px solid #ccc",
    backgroundColor: "rgb(211, 211, 211, 0.5)",
    height: 38,
    textAlign: "left",
    width: 250,
    fontSize: 14,
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.4)",
  }),
  option: (provided) => ({
    ...provided,
    borderBottom: "1px solid #ccc",
    color: "#333",
    padding: 10,
    textAlign: "left",
    fontSize: 13,
    fontWeight: 600,
  }),
};

const Dropdown = ({ options, text, onChange }) => {
  return (
    <div>
      <Select
        options={options}
        onChange={onChange}
        placeholder={text}
        styles={customStyles}
      />
    </div>
  );
};

export default Dropdown;
