import React from "react";
import COLORS from "../../utils/colors";

const Calendar = () => {
  // Get current date
  const currentDate = new Date();

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get month name
  const monthName = monthNames[currentDate.getMonth()];

  // Get number of days in the current month
  const numberOfDays = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();

  // Get the index of the first day of the month (0 for Sunday, 1 for Monday, etc.)
  const firstDayIndex = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();

  // Array of day names
  const dayNames = ["S", "M", "T", "W", "T", "F", "S"];

  //   const daysFromStart = [
  //     ...dayNames.slice(firstDayIndex),
  //     ...dayNames.slice(0, firstDayIndex),
  //   ];

  // Group the days into rows of 7 columns
  const groupedDays = Array.from(
    { length: Math.ceil((numberOfDays + firstDayIndex) / 7) },
    (_, rowIndex) =>
      Array.from(
        { length: 7 },
        (_, colIndex) => rowIndex * 7 + colIndex + 1 - firstDayIndex
      )
  );

  return (
    <div
      className="col-sm-12 boxShadow boxShadowHover"
      style={{
        backgroundColor: "#fff",
        borderRadius: 8,
        fontFamily: "Open Sans",
        padding: 15,
      }}
    >
      <h6
        style={{
          padding: 5,
          color: COLORS.green,
          fontWeight: "700",
          textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
          fontSize: 14,
          marginBottom: "6%"
        }}
      >
        {monthName}
      </h6>
      <div style={{ display: "flex" }}>
        {dayNames.map((day) => (
          <div
            key={day}
            style={{
              flex: 1,
              textAlign: "center",
              fontSize: 12,
              fontWeight: "700",
              color: COLORS.orange,
              textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            {day}
          </div>
        ))}
      </div>
      {groupedDays.map((row, rowIndex) => (
        <div key={rowIndex} style={{ display: "flex" }}>
          {row.map((day) => (
            <div
              key={day}
              style={{
                flex: 1,
                textAlign: "center",
                // border: "1px solid #ccc",
                padding: "5px",
                width: "calc(100% / 7)",
                fontSize: 12,
                fontWeight: "700",
              }}
            >
              {day > 0 && day <= numberOfDays ? day : ""}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Calendar;
