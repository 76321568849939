import React from "react";
import { useNavigate } from "react-router-dom";
import COLORS from "../../utils/colors";
import {
  containerLayout,
  logoStyles,
  formLayout,
  labelStyle,
  inputFieldStyle,
  buttonStyle,
} from "./formStyles";

export default function Login() {
  const navigate = useNavigate();

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div style={containerLayout}>
        <div style={logoStyles}>
          <img src="/asstes/images/logo.svg" />
        </div>
        <div className="col" style={formLayout}>
          <div className="row" style={{ width: "100%" }}>
            <span className="p-0" style={labelStyle}>
              Email
            </span>
            <input placeholder="xyz@abc.com" style={inputFieldStyle} />
          </div>

          <div className="row" style={{ width: "100%", marginTop: "5%" }}>
            <span className="p-0" style={labelStyle}>
              Password
            </span>
            <input placeholder="****" type="password" style={inputFieldStyle} />
          </div>

          <button style={buttonStyle} onClick={() => navigate("/dashboard")}>
            Login
          </button>

          <a
            href="/signup"
            onClick={() => navigate("/signup")}
            style={{
              fontFamily: "Open Sans",
              fontSize: 10,
              fontWeight: 500,
              color: COLORS.white,
              marginTop: "4%",
            }}
          >
            Create Account
          </a>
        </div>
      </div>
    </div>
  );
}
