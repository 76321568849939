import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import COLORS from "../../utils/colors";
import {
  containerLayoutSignup,
  logoStyles,
  formLayout,
  labelStyle,
  inputFieldStyle,
  buttonStyle,
} from "./formStyles";

export default function Signup() {
  const [value, setValue] = useState("");

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div style={containerLayoutSignup}>
        <div style={logoStyles}>
          <img src="/asstes/images/logo.svg" />
        </div>
        <div className="col" style={formLayout}>
          <div className="row" style={{ width: "100%" }}>
            <span className="p-0" style={labelStyle}>
              First Name
            </span>
            <input placeholder="John" style={inputFieldStyle} />
          </div>

          <div className="row" style={{ width: "100%", marginTop: "5%" }}>
            <span className="p-0" style={labelStyle}>
              Last Name
            </span>
            <input placeholder="Doe" style={inputFieldStyle} />
          </div>

          <div className="row" style={{ width: "100%", marginTop: "5%" }}>
            <span className="p-0" style={labelStyle}>
              Phone
            </span>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              //   placeholder="Enter phone number"
              defaultCountry="NG"
              value={value}
              onChange={(newValue) => setValue(newValue)}
              style={{ ...inputFieldStyle, border: "none" }}
            />
          </div>

          <div className="row" style={{ width: "100%", marginTop: "5%" }}>
            <span className="p-0" style={labelStyle}>
              Email
            </span>
            <input placeholder="abc@xyz.com" style={inputFieldStyle} />
          </div>

          <div className="row" style={{ width: "100%", marginTop: "5%" }}>
            <span className="p-0" style={labelStyle}>
              Create Password
            </span>
            <input placeholder="****" type="password" style={inputFieldStyle} />
          </div>

          <button style={buttonStyle}>Signup</button>

          <a href="/login"
            style={{
              fontFamily: "Open Sans",
              fontSize: 10,
              fontWeight: 500,
              color: COLORS.white,
              marginTop: "4%",
            }}
          >
            Already have account! <span style={{ fontWeight: 600 }}>Login</span>
          </a>
        </div>
      </div>
    </div>
  );
}
