import React from "react";
import TotalBalance from "../../components/DashboardComponents/TotalBalance";
import DepositCard from "../../components/DashboardComponents/DepositCard";
import Calendar from "../../components/DashboardComponents/Calender";
import LineChart from "../../components/DashboardComponents/Chart";
import Transactions from "../../components/DashboardComponents/Transactions";

export default function Dashboard() {
  return (
    <div className="col-sm-12">
      <div className="row" style={{ padding: 5 }}>
        <div className="col-sm-8">
          <div className="col-sm-12">
            <div
              className="row"
              style={{
                // width: "100%",
                display: "flex",
                // alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <TotalBalance />

              <DepositCard />
            </div>
          </div>

          <div
            className="col-sm-12 boxShadow"
            style={{
              padding: 5,
              marginTop: "1.5%",
              borderRadius: 7,
              backgroundColor: "#fff",
              width: "98%",
              marginLeft: "1%",
            }}
          >
            <LineChart />
          </div>
        </div>
        <div className="col-sm-4">
          <Calendar />
          <Transactions />
        </div>
      </div>
    </div>
  );
}
