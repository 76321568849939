import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import { FaMoneyBillTransfer, FaFileInvoiceDollar } from "react-icons/fa6";
import COLORS from "../../utils/colors";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillHome color={COLORS.grey} />,
    iconActive: <AiIcons.AiFillHome color={COLORS.orange} />,
    cName: "nav-text",
  },
  {
    title: "Transfer",
    path: "/transfer",
    icon: <FaMoneyBillTransfer color={COLORS.grey} />,
    iconActive: <FaMoneyBillTransfer color={COLORS.orange} />,
    cName: "nav-text",
  },
  {
    title: "Bill Payments",
    path: "/bill-payments",
    icon: <FaFileInvoiceDollar color={COLORS.grey} />,
    iconActive: <FaFileInvoiceDollar color={COLORS.orange} />,
    cName: "nav-text",
  },
  {
    title: "Support",
    path: "/support",
    icon: <IoIcons.IoMdHelpCircle color={COLORS.grey} />,
    iconActive: <IoIcons.IoMdHelpCircle color={COLORS.orange} />,
    cName: "nav-text",
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <IoIcons.IoMdSettings color={COLORS.grey} />,
    iconActive: <IoIcons.IoMdSettings color={COLORS.orange} />,
    cName: "nav-text",
  },
];
