import React from "react";
import { useNavigate } from "react-router-dom";
import { FaAddressCard, FaUserShield, FaListAlt } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { MdChecklistRtl } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import COLORS from "../utils/colors";

const labelStyle = { fontSize: 13, fontWeight: 600, marginLeft: "-4%" };
const rowLayout = {
  alignItems: "center",
  justifyContent: "center",
  padding: 10,
};

export default function Settings() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        padding: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Open Sans",
        marginTop: "5%",
      }}
    >
      <div
        className="col-sm-4 boxShadow"
        style={{ backgroundColor: "#fff", padding: 15, borderRadius: 7 }}
      >
        <div
          style={{
            color: COLORS.green,
            fontWeight: "bold",
            fontSize: 14,
            textAlign: "center",
            marginBottom: "7%",
            marginTop: "5%",
            textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          Account Settings
        </div>

        <div
          className="row row-hover-effect"
          style={rowLayout}
          onClick={() => navigate("/profile")}
        >
          <FaAddressCard
            className="col-sm-2"
            color={COLORS.orange}
            size={20}
            style={{ textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)" }}
          />
          <div className="col-sm-8" style={labelStyle}>
            Profile Information
          </div>
          <IoIosArrowForward
            className="col-sm-2"
            color={COLORS.orange}
            size={20}
          />
        </div>

        <div
          className="row row-hover-effect"
          style={{ ...rowLayout, marginTop: "2%" }}
        >
          <FaUserShield className="col-sm-2" color={COLORS.orange} size={20} />
          <div className="col-sm-8" style={labelStyle}>
            Verification
          </div>
          <IoIosArrowForward
            className="col-sm-2"
            color={COLORS.orange}
            size={20}
          />
        </div>

        <div
          className="row row-hover-effect"
          style={{ ...rowLayout, marginTop: "2%" }}
        >
          <BsBank2 className="col-sm-2" color={COLORS.orange} size={20} />
          <div className="col-sm-8" style={labelStyle}>
            Bank Accounts
          </div>
          <IoIosArrowForward
            className="col-sm-2"
            color={COLORS.orange}
            size={20}
          />
        </div>

        <div
          className="row row-hover-effect"
          style={{ ...rowLayout, marginTop: "2%" }}
          onClick={() => navigate("/privacy-policy")}
        >
          <FaListAlt className="col-sm-2" color={COLORS.orange} size={20} />
          <div className="col-sm-8" style={labelStyle}>
            Privacy Policy
          </div>
          <IoIosArrowForward
            className="col-sm-2"
            color={COLORS.orange}
            size={20}
          />
        </div>

        <div
          className="row row-hover-effect"
          style={{ ...rowLayout, marginTop: "2%" }}
          onClick={() => navigate("/terms-n-conditions")}
        >
          <MdChecklistRtl
            className="col-sm-2"
            color={COLORS.orange}
            size={20}
          />
          <div className="col-sm-8" style={labelStyle}>
            Terms & Conditions
          </div>
          <IoIosArrowForward
            className="col-sm-2"
            color={COLORS.orange}
            size={20}
          />
        </div>
      </div>
    </div>
  );
}
