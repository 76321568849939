import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdDownload } from "react-icons/io";
import { FaShareAlt } from "react-icons/fa";
// import "./MultiStepForm.css";
import COLORS from "../../utils/colors";

const labelStyle = {
  fontFamily: "Open Sans",
  fontSize: 11,
  fontWeight: 800,
  textAlign: "left",
  marginBottom: "1%",
  textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const inputFieldStyle = {
  borderRadius: 10,
  border: "1px solid #ccc",
  backgroundColor: "#d3d3d3",
  height: 38,
  textAlign: "left",
  width: 250,
  fontSize: 13,
  padding: 10,
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.4)",
};

const buttonStyle = {
  backgroundColor: COLORS.green,
  borderRadius: 10,
  textAlign: "center",
  width: 156,
  height: 42,
  fontFamily: "Open Sans",
  fontSize: 15,
  fontWeight: "bold",
  // marginTop: "8%",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
  color: "#fff",
  border: "none",
};

const roundButtonStyle = {
  width: 35,
  height: 35,
  backgroundColor: COLORS.green,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 20,
  borderWidth: 2,
  borderColor: "#d9d9d9",
  borderStyle: "solid",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
};

const BillDetails = () => {
  const navigate = useNavigate();

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
  };

  return (
    <div className="col-sm-12" style={{ padding: 20, fontFamily: "Open Sans" }}>
      <h6
        style={{
          fontSize: 12,
          fontWeight: "bold",
          textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Bill Payments / Electricity
      </h6>
      <div className="row justify-content-center ">
        <div
          style={{
            marginTop: "5%",
            padding: 20,
            fontFamily: "Open Sans",
            backgroundColor: "#fff",
            borderRadius: 7,
            width: "50%",
          }}
          className="col-sm-7 boxShadow"
        >
          {/* Fieldsets */}

          {/* Transaction Status */}
          <fieldset>
            {/* <h2 className="fs-title">Social Profiles</h2> */}
            <h6
              style={{
                marginTop: "3%",
                marginBottom: "5%",
                textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                color: COLORS.green,
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Bill Details
            </h6>

            <div
              className="col-sm-12"
              style={{
                backgroundColor: "#d9d9d9",
                borderRadius: 7,
                padding: 20,
                boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="row justify-content-between gap-5">
                <div
                  className="col"
                  style={{ ...labelStyle, textAlign: "right" }}
                >
                  <div>Transaction ID</div>
                  <div className="mt-2">Bill Id</div>
                  <div className="mt-2">Date</div>
                  <div className="mt-2">Transaction Type</div>
                  <div className="mt-2">Biller</div>
                  <div className="mt-2">Bill Amount</div>
                  <div className="mt-2">Status</div>
                </div>
                <div
                  className="col"
                  style={{ ...labelStyle, textAlign: "left", opacity: 0.8 }}
                >
                  <div>2748569855</div>
                  <div className="mt-2">2748569855</div>
                  <div className="mt-2">03 Mar 2024 (05:13 pm)</div>
                  <div className="mt-2">Electric Bill Payment</div>
                  <div className="mt-2">Company 1</div>
                  <div className="mt-2" style={{ color: "#000" }}>
                    $ 50
                  </div>
                  <div
                    className="mt-2"
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      textDecoration: "underline",
                    }}
                  >
                    UNPAID
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                // backgroundColor: "#000",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                marginTop: "5%",
              }}
            >
              <input
                type="button"
                name="next"
                className="boxShadow"
                value="Pay Bill"
                onClick={() => navigate("/billreciept")}
                style={buttonStyle}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "Space-between",
                  // marginTop: "5%",
                  width: "15%",
                }}
              >
                <div style={roundButtonStyle}>
                  <IoMdDownload size={25} color="#d9d9d9" />
                </div>
                <div
                  style={{
                    ...roundButtonStyle,
                    backgroundColor: "#d9d9d9",
                    marginLeft: "3%",
                  }}
                >
                  <FaShareAlt size={20} color={COLORS.orange} />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default BillDetails;
