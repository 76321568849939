import React from "react";
import { useNavigate } from "react-router-dom";
import COLORS from "../utils/colors";

const buttonCardStyle = {
  // backgroundColor: "#fff",
  width: 160,
  height: 140,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 7.03,
};

const cardLabelStyle = {
  fontSize: 15,
  fontWeight: 700,
  color: COLORS.green,
  textAlign: "center",
  marginTop: "3%",
};

export default function BillPayments() {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/paybill");
  };

  return (
    <div style={{ fontFamily: "Open Sans", padding: 20 }}>
      <h6
        style={{
          fontSize: 12,
          fontWeight: "bold",
          textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Bill Payments
      </h6>

      <div
        className="row gap-5"
        style={{ padding: 15, justifyContent: "center" }}
      >
        <div
          className="col-sm-2 boxShadowHoverColor"
          style={buttonCardStyle}
          onClick={handleRoute}
        >
          <img
            src="/asstes/images/icons/electricityIcon.svg"
            alt="logo"
            style={{ width: 90, height: 90 }}
          />
          <div style={cardLabelStyle}>Electricity</div>
        </div>

        <div className="col-sm-2 boxShadowHoverColor" style={buttonCardStyle}>
          <img
            src="/asstes/images/icons/tvIcon.svg"
            alt="logo"
            style={{ width: 90, height: 90 }}
          />
          <div style={cardLabelStyle}>Cable TV</div>
        </div>

        <div className="col-sm-2 boxShadowHoverColor" style={buttonCardStyle}>
          <img
            src="/asstes/images/icons/internet-security-icon.svg"
            alt="logo"
            style={{ width: 90, height: 90 }}
          />
          <div style={cardLabelStyle}>Data Bundles</div>
        </div>

        <div className="col-sm-2 boxShadowHoverColor" style={buttonCardStyle}>
          <img
            src="/asstes/images/icons/mobileIcon.svg"
            alt="logo"
            style={{ width: 90, height: 90 }}
          />
          <div style={cardLabelStyle}>Airtime</div>
        </div>

        <div
          className="col-sm-2 boxShadow"
          style={buttonCardStyle}
        >
          <img
            src="/asstes/images/icons/more.svg"
            alt="logo"
            style={{ width: 90, height: 90 }}
          />
          <div style={cardLabelStyle}>More...</div>
        </div>
      </div>
    </div>
  );
}
