import React from "react";

export default function MobileApp() {
  return (
    <div>
      Download App from google playstore or iTunes OR open website in laptop or
      desktop
    </div>
  );
}
