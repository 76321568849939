import React from "react";
import COLORS from "../../utils/colors";
import { cardBoxLayout } from "./styling";

export default function TotalBalance() {
  return (
    <div className="col-sm-6 m-1 boxShadow boxShadowHover" style={cardBoxLayout}>
      <div className="row align-items-center" style={{ height: "100%" }}>
        <div className="col" style={{ marginLeft: "2%" }}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
              marginBottom: "3%",
            }}
          >
            Total Balance
          </div>
          <div
            style={{
              fontSize: 30,
              fontWeight: "bold",
              textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
              color: COLORS.green,
            }}
          >
            $ 7,560.00
            <span
              style={{
                fontSize: 12,
                fontWeight: 600,
                marginLeft: "2%",
              }}
            >
              USD
            </span>
          </div>
        </div>
        <div
          className="col"
          style={{
            fontSize: 14,
            fontWeight: "bold",
            textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            color: COLORS.orange,
            textAlign: "center",
          }}
        >
          <span style={{ marginLeft: "30%" }}>Withdraw</span>
        </div>
      </div>
    </div>
  );
}
