import React from "react";

const TermsAndConditions = () => {
  return (
    <div
      style={{
        padding: 20,
        fontFamily: "Open Sans",
        overflowY: "auto",
        maxHeight: "100%",
        fontSize: 14,
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: 18,
          marginBottom: "1%",
          textShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Terms & Conditions
      </div>
      <p>
        These terms and conditions outline the rules and regulations for the use
        of vivapay' Website, located at vivapay.com. By accessing this website,
        you agree to these terms and conditions. Please do not continue to use
        vivapay.com if you do not agree with all of the terms and conditions
        stated on this page.
      </p>
      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: “Client”, “You” and
        “Your” refers to you, the person log on this website and compliant to
        the Company’s terms and conditions. “The Company”, “Ourselves”, “We”,
        “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”,
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client’s needs in respect of
        provision of the Company’s stated services, in accordance with and
        subject to, prevailing law of [COUNTRY]. Any use of the above
        terminology or other words in the singular, plural, capitalization
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to same.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>Cookies</h6>
      <p>
        We employ the use of cookies. By accessing vivapay.com, you agree to use
        cookies in agreement with the vivapay' Terms and Conditions and Privacy
        Policy.
      </p>
      <p>
        Most interactive websites use cookies to let us retrieve the user’s
        details for each visit. In order to provide you with access to the
        Website and a more personalized and responsive service, we need to
        remember and store information about how you use this website. This is
        done using small text files called cookies. Cookies contain small
        amounts of information and are downloaded to your computer or another
        device by a server for this Website. Your web browser then sends these
        cookies back to this website on each subsequent visit so that it can
        derecognize and remember things like your user preferences (visits,
        clicks, historical activity). You can find more detailed information
        about cookies and how they work at http://www.aboutcookies.org.
      </p>

      <p>
        Cookies are used by our website to enable the functionality of certain
        areas to make it easier for people visiting our website. Some of our
        affiliate/advertising partners may also use cookies (necessary cookies).
      </p>

      <p>
        This site offers newsletter or email subscription services and cookies
        may be used to remember if you are already registered and whether to
        show certain notifications which might only be valid for
        subscribed/unsubscribed users. When you submit data through a form such
        as those found on contact pages or comment forms, cookies may be set to
        remember your user details for future correspondence (functional
        cookies).
      </p>

      <p>
        We or our service providers also use analytic services to help us
        understand how effective our content is, what interests our users have,
        and to improve how this Website works. In addition, we use web beacons
        or tracking pixels to count visitor numbers and performance cookies to
        track how many individual users access this Website and how often.
        Generated by Termzy.io. This information is used for statistical
        purposes only and it is not our intention to use such information to
        personally identify any user.
      </p>

      <p>
        The browsers of most computers, smartphones, and other web-enabled
        devices are typically set up to accept cookies. If you wish to amend
        your cookie preferences for this website or any other websites you can
        do this through your browser settings. Your browser’s ‘help’ function
        will tell you how to do this. Be aware that disabling cookies will
        affect the functionality of this website in areas in which cookies are
        necessary for the functioning of features, and usually results in also
        disabling certain functionalities and features on this site. Therefore,
        it is recommended that you do not disable cookies.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>
        License and Intellectual Property Rights
      </h6>
      <p>
        Unless otherwise stated, VIVAPAY, and/or its licensors own the
        intellectual property rights for all material on vivapay.com. This
        website and its contents are protected by copyright, trademark, and
        other laws of [Country], and/or foreign countries. We and our licensors
        reserve all rights not expressly granted under these Terms of Use.
      </p>

      <p>
        You may access this website for your own personal use subject to
        restrictions set in these terms and conditions.
      </p>
      <p>You must not;</p>

      <ul>
        <li>Republish material from vivapay.com</li>
        <li>Use material from vivapay.com for commercial purposes</li>
        <li>Sell, rent, or sub-license material from vivapay.com</li>
        <li>Reproduce, duplicate, modify or copy material from vivapay.com</li>
        <li>Redistribute content from vivapay.com</li>
      </ul>

      <p>
        You shall not use the "VIVAPAY" name or the VIVAPAY logo, either alone
        or in combination with other words or design elements. You may not use
        any of the foregoing names, marks, or logos in any press release,
        advertisement, or other promotional or marketing material or media,
        whether in written, oral, electronic, visual, or any other form, except
        if expressly permitted in writing by VIVAPAY.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>
        Reservation of Rights
      </h6>
      <p>
        We reserve the right to request that you remove all links or any
        particular link to our website. You approve of immediately removing all
        links to our website upon request. We also reserve the right to amend
        these terms and conditions at any time. By linking to our website, you
        agree to be bound to and follow these terms and conditions.
      </p>

      <p>
        We may also share and disclose other information that we collect,
        including aggregate information, as we consider necessary to develop and
        provide our Services, including in the ways described above. The
        information that we share in this way would not be considered to
        personally identify an individual.
      </p>

      <h6 style={{ fontWeight: 700, marginTop: "3%" }}>
        Disclaimers and Limitations of Liability
      </h6>

      <p>
        This website is provided as is, and we make no express or implied
        representations or warranties regarding it. Without limiting the
        foregoing, we do not warrant that this website will be secure,
        error-free, free from viruses or malicious code, or will meet any
        particular criteria of performance or quality. We expressly disclaim all
        implied warranties, including, without limitation, warranties of
        merchantability, title, fitness for a particular purpose,
        non-infringement, compatibility, security, and accuracy.
      </p>
    </div>
  );
};

export default TermsAndConditions;
