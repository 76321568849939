import React from "react";
import MulMultiStepFormComponent from "../components/MultiStepForm/MultiStepForm";

export default function Transfer() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MulMultiStepFormComponent />
    </div>
  );
}
