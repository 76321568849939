import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Login from "./pages/Auth/Login";
// import LandingPage from "./pages/LandingPage";
import Signup from "./pages/Auth/Signup";
import Dashboard from "./pages/Dashboard/Dashboard";
import Transfer from "./pages/Transfer";
import BillPayments from "./pages/BillPayments";
import Support from "./pages/Support";
import Settings from "./pages/Settings";
import { isMobile } from "react-device-detect";
import MobileApp from "./pages/MobileApp";
import PayBill from "./pages/Bills/PayBill";
import BillDetails from "./pages/Bills/BillDetails";
import Profile from "./pages/Profile/Profile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import BillReciept from "./pages/Bills/BillReciept";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* <Route path="/" element={<LandingPage />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          {/* Check if the device is mobile */}
          {isMobile ? (
            <Route path="*" element={<MobileApp />} />
          ) : (
            // Render the nested routes only if the device is not mobile
            <Route
              path="/*"
              element={
                <>
                  <Sidebar />
                  <div
                    className="content" //content styling is in sidebar styles.css
                    style={{
                      backgroundColor: "#e9f5fe",
                      height: "90vh",
                      overflow: "hidden",
                    }}
                  >
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/transfer" element={<Transfer />} />
                      <Route path="/bill-payments" element={<BillPayments />} />
                      <Route path="/paybill" element={<PayBill />} />
                      <Route path="/billdetails" element={<BillDetails />} />
                      <Route path="/billreciept" element={<BillReciept />} />
                      <Route path="/support" element={<Support />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route
                        path="/terms-n-conditions"
                        element={<TermsAndConditions />}
                      />
                    </Routes>
                  </div>
                </>
              }
            />
          )}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
